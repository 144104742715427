import { ICity, ICounty, IState } from "../../types";
import { LocationActionsTypes } from "../actions/locationActions";

export const SET_IS_GETTING_STATES_IN_PROGRESS =
  "location/SET_IS_GETTING_STATES_IN_PROGRESS";
export const SET_STATES = "location/SET_STATES";
export const SET_CITIES = "location/SET_CITIES";
export const SET_COUNTIES = "location/SET_COUNTIES";
export const SET_IS_GETTING_CITY_BY_STATE_IN_PROGRESS =
  "location/SET_IS_GETTING_CITY_BY_STATE_IN_PROGRESS";
export const SET_IS_GETTING_COUNTIES_IN_PROGRESS =
  "location/SET_IS_GETTING_COUNTIES_IN_PROGRESS";
export const SET_IS_GETTING_CITIES_BY_PART_NAME_IN_PROGRESS =
  "location/SET_IS_GETTING_CITIES_BY_PART_NAME_IN_PROGRESS";
export const SET_CITIES_SEGGESTIONS = "location/SET_CITIES_SEGGESTIONS";

const initialState = {
  isGettingStatesInProgress: false,
  isGettingCityByStateInProgress: false,
  isGettingCountiesInProgress: false,
  isGettingCitiesByPartNameInProgress: false,
  states: [] as IState[],
  cities: [] as ICity[],
  counties: [] as ICounty[],
  citiesSuggestions: [] as [string, string][],
};

type InitialStateType = typeof initialState;

export const locationReducer = (
  state = initialState,
  action: LocationActionsTypes
): InitialStateType => {
  switch (action.type) {
    case SET_IS_GETTING_STATES_IN_PROGRESS:
    case SET_STATES:
    case SET_IS_GETTING_CITY_BY_STATE_IN_PROGRESS:
    case SET_IS_GETTING_CITIES_BY_PART_NAME_IN_PROGRESS:
    case SET_IS_GETTING_COUNTIES_IN_PROGRESS:
    case SET_CITIES:
    case SET_COUNTIES:
    case SET_CITIES_SEGGESTIONS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
