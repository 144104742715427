import React, { useEffect, useState, Dispatch } from "react";
import { Select } from "antd";
import type { SelectProps } from "antd";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCitiesByPartName,
  LocationThunkType,
} from "../../store/actions/locationActions";
import { AppStateType } from "../../store";

const SearchInput: React.FC<{
  placeholder: string;
  currentSearchParams?: { [param: string]: string };
}> = (props) => {
  const dispatch = useDispatch() as Dispatch<LocationThunkType>;
  let [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState<SelectProps["options"]>([]);
  const [value, setValue] = useState<string[]>();
  const citiesSuggestions = useSelector(
    (state: AppStateType) => state.location.citiesSuggestions
  );
  const handleSearch = (newValue: string) => {
    if (newValue.length >= 3) {
      dispatch(getCitiesByPartName(newValue));
    }
  };

  useEffect(() => {
    const data = citiesSuggestions.map((item: any) => ({
      value: item[1],
      text: item[0],
    }));
    setData(data);
  }, [citiesSuggestions]);

  const handleChange = (newValue: string[]) => {
    setValue(newValue);

    if (newValue[0] && newValue[0].length === 24) {
      setSearchParams({
        ...props.currentSearchParams,
        city: newValue.toString(),
      });
    } else {
      searchParams.delete("city");
      setSearchParams(searchParams);
    }
  };

  return (
    <Select
      style={{ minWidth: 120 }}
      size="small"
      showSearch
      mode="tags"
      value={value}
      placeholder={props.placeholder}
      defaultActiveFirstOption={false}
      suffixIcon={null}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={null}
      options={(data || []).map((d) => ({
        value: d.value,
        label: d.text,
      }))}
    />
  );
};

interface IProps {
  currentSearchParams?: { [param: string]: string };
}

export const SearchCityInput = ({ currentSearchParams }: IProps) => (
  <SearchInput placeholder="City" currentSearchParams={currentSearchParams} />
);
