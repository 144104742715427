import { ThunkAction } from "redux-thunk";
import { AppStateType } from "..";
import {
  SET_IS_GETTING_SITES_IN_PROGRESS,
  SET_IS_CREATING_SITE_IN_PROGRESS,
  SET_SITES,
  SET_SORT_BY_FIELD,
  SET_SORT_BY,
  SET_PAGE,
  SET_SITES_TOTAL,
  SET_SEARCH_BY_NAME,
  SET_SEARCH_BY_STATE,
  SET_SEARCH_BY_CITY,
  SET_SEARCH_BY_ZIP,
  SET_SITE,
  SET_IS_UPDATING_SITE_IN_PROGRESS,
  SET_IS_LOADING_SPECIFIC_PAGE_VALUES_IN_PROGRESS,
  SET_SPECIFIC_PAGE_VALUES,
  SET_IS_UPDATING_SPECIFIC_PAGE_VALUES_IN_PROGRESS,
  SET_IS_DELETION_IN_PROGRESS,
  SET_IN_PROGRESS_SITE_ID,
  IS_DEPLOY_REQUEST_IN_PROGRESS,
  SET_SEARCH_BY_DOMAIN,
  SET_SEARCH_BY_COMPANY,
  SET_SEARCH_BY_STATUS,
  SET_IS_REBUILD_WITH_NEW_CONTENT_IN_PROGRESS,
  SET_SEARCH_BY_COUNTY,
  SET_SEARCH_BY_ERROR,
  SET_SEARCH_BY_IN_PROGRESS,
  SET_IS_WRITING_ARTICLE_IN_PROGRESS,
} from "../reducers/siteReducer";
import { siteAPI, tagsApi } from "../../api";
import { ISite, ISpecificPageValue, SortByField } from "../../types";
import { FormInstance, notification } from "antd";

type SetIsGettingSitesInProgress = {
  type: typeof SET_IS_GETTING_SITES_IN_PROGRESS;
  payload: { isGettingSitesInProgress: boolean };
};

type SetIsCreatingSiteInProgress = {
  type: typeof SET_IS_CREATING_SITE_IN_PROGRESS;
  payload: { isCreatingSiteInProgress: boolean };
};

type SetIsUpdatingSiteInProgress = {
  type: typeof SET_IS_UPDATING_SITE_IN_PROGRESS;
  payload: { isUpdatingSiteInProgress: boolean };
};

type SetSites = {
  type: typeof SET_SITES;
  payload: { sites: ISite[] };
};

type SetSite = {
  type: typeof SET_SITE;
  payload: { site: ISite };
};

type SetSortByField = {
  type: typeof SET_SORT_BY_FIELD;
  payload: { sortByField: SortByField };
};

type SetSortBy = {
  type: typeof SET_SORT_BY;
  payload: { sortBy: "asc" | "desc" };
};

type SetPage = {
  type: typeof SET_PAGE;
  payload: { page: number };
};

type SetSitesTotal = {
  type: typeof SET_SITES_TOTAL;
  payload: { sitesTotal: number };
};

type SetSearchByName = {
  type: typeof SET_SEARCH_BY_NAME;
  payload: { serchByName: string };
};

type SetSearchByState = {
  type: typeof SET_SEARCH_BY_STATE;
  payload: { serchByState: string };
};

type SetSearchByCity = {
  type: typeof SET_SEARCH_BY_CITY;
  payload: { serchByCity: string };
};

type SetSerchByZip = {
  type: typeof SET_SEARCH_BY_ZIP;
  payload: { serchByZip: string };
};

type SetSerchByDomain = {
  type: typeof SET_SEARCH_BY_DOMAIN;
  payload: { serchByDomain: string };
};

type SetSearchByCompany = {
  type: typeof SET_SEARCH_BY_COMPANY;
  payload: { serchByCompany: string };
};

type SetSearchByStatus = {
  type: typeof SET_SEARCH_BY_STATUS;
  payload: { serchByStatus: string };
};

type SetSerchByCounty = {
  type: typeof SET_SEARCH_BY_COUNTY;
  payload: { serchByCounty: string };
};

type SetIsLoadingSpecificPageValuesInProgress = {
  type: typeof SET_IS_LOADING_SPECIFIC_PAGE_VALUES_IN_PROGRESS;
  payload: { isLoadingSpecificPageValuesInProgress: boolean };
};

type SetIsUpdSpecificPageValuesInProgress = {
  type: typeof SET_IS_UPDATING_SPECIFIC_PAGE_VALUES_IN_PROGRESS;
  payload: { isUpdSpecificPageValuesInProgress: boolean };
};

type SetIsRebuildWithNewContentInProgress = {
  type: typeof SET_IS_REBUILD_WITH_NEW_CONTENT_IN_PROGRESS;
  payload: { isRebuildWithNewContentInProgress: boolean };
};

type SetIsDeployRequestInProgress = {
  type: typeof IS_DEPLOY_REQUEST_IN_PROGRESS;
  payload: { isDeployRequestInProgress: boolean };
};

type SetSpecificPageValues = {
  type: typeof SET_SPECIFIC_PAGE_VALUES;
  payload: { specificPageValues: ISpecificPageValue[] };
};

type SetIsDeletionInProgress = {
  type: typeof SET_IS_DELETION_IN_PROGRESS;
  payload: { isDeletionInProgress: boolean };
};

type SetInProgressSiteId = {
  type: typeof SET_IN_PROGRESS_SITE_ID;
  payload: { inProgressId: string | null };
};

type SetSearchByError = {
  type: typeof SET_SEARCH_BY_ERROR;
  payload: { serchByError: string };
};

type SetSearchByInProgress = {
  type: typeof SET_SEARCH_BY_IN_PROGRESS;
  payload: { searchByInProgress: string };
};

type SetIsWritingArticleInProgress = {
  type: typeof SET_IS_WRITING_ARTICLE_IN_PROGRESS;
  payload: { isWritingArticleInProgress: boolean };
};

export type SiteActionsTypes =
  | SetIsGettingSitesInProgress
  | SetIsCreatingSiteInProgress
  | SetSites
  | SetSortByField
  | SetSortBy
  | SetPage
  | SetSitesTotal
  | SetSearchByName
  | SetSearchByState
  | SetSearchByCity
  | SetSite
  | SetIsUpdatingSiteInProgress
  | SetIsLoadingSpecificPageValuesInProgress
  | SetSpecificPageValues
  | SetIsUpdSpecificPageValuesInProgress
  | SetIsDeletionInProgress
  | SetInProgressSiteId
  | SetIsDeployRequestInProgress
  | SetSerchByZip
  | SetSerchByDomain
  | SetSearchByCompany
  | SetSearchByStatus
  | SetIsRebuildWithNewContentInProgress
  | SetSerchByCounty
  | SetSearchByError
  | SetSearchByInProgress
  | SetIsWritingArticleInProgress;

export const setIsGettingSitesInProgress = (
  isGettingSitesInProgress: boolean
): SetIsGettingSitesInProgress => ({
  type: SET_IS_GETTING_SITES_IN_PROGRESS,
  payload: { isGettingSitesInProgress },
});

export const setIsCreatingSiteInProgress = (
  isCreatingSiteInProgress: boolean
): SetIsCreatingSiteInProgress => ({
  type: SET_IS_CREATING_SITE_IN_PROGRESS,
  payload: { isCreatingSiteInProgress },
});

export const setIsUpdatingSiteInProgress = (
  isUpdatingSiteInProgress: boolean
): SetIsUpdatingSiteInProgress => ({
  type: SET_IS_UPDATING_SITE_IN_PROGRESS,
  payload: { isUpdatingSiteInProgress },
});

export const setSites = (sites: ISite[]): SetSites => ({
  type: SET_SITES,
  payload: { sites },
});

export const setSite = (site: ISite): SetSite => ({
  type: SET_SITE,
  payload: { site },
});

export const setSortByField = (sortByField: SortByField): SetSortByField => ({
  type: SET_SORT_BY_FIELD,
  payload: { sortByField },
});

export const setSortBy = (sortBy: "asc" | "desc"): SetSortBy => ({
  type: SET_SORT_BY,
  payload: { sortBy },
});

export const setPage = (page: number): SetPage => ({
  type: SET_PAGE,
  payload: { page },
});

export const setSitesTotal = (sitesTotal: number): SetSitesTotal => ({
  type: SET_SITES_TOTAL,
  payload: { sitesTotal },
});

export const setSearchByName = (serchByName: string): SetSearchByName => ({
  type: SET_SEARCH_BY_NAME,
  payload: { serchByName },
});

export const setSearchByState = (serchByState: string): SetSearchByState => ({
  type: SET_SEARCH_BY_STATE,
  payload: { serchByState },
});

export const setSearchByCity = (serchByCity: string): SetSearchByCity => ({
  type: SET_SEARCH_BY_CITY,
  payload: { serchByCity },
});

export const setSearchByZip = (serchByZip: string): SetSerchByZip => ({
  type: SET_SEARCH_BY_ZIP,
  payload: { serchByZip },
});

export const setSearchByCounty = (serchByCounty: string): SetSerchByCounty => ({
  type: SET_SEARCH_BY_COUNTY,
  payload: { serchByCounty },
});

export const setSearchByStatus = (
  serchByStatus: string
): SetSearchByStatus => ({
  type: SET_SEARCH_BY_STATUS,
  payload: { serchByStatus },
});

export const setSearchByError = (serchByError: string): SetSearchByError => ({
  type: SET_SEARCH_BY_ERROR,
  payload: { serchByError },
});

export const setSearchByInProgress = (
  searchByInProgress: string
): SetSearchByInProgress => ({
  type: SET_SEARCH_BY_IN_PROGRESS,
  payload: { searchByInProgress },
});

export const setSerchByDomain = (serchByDomain: string): SetSerchByDomain => ({
  type: SET_SEARCH_BY_DOMAIN,
  payload: { serchByDomain },
});

export const setSearchByCompany = (
  serchByCompany: string
): SetSearchByCompany => ({
  type: SET_SEARCH_BY_COMPANY,
  payload: { serchByCompany },
});

export const setSpecificPageValues = (
  specificPageValues: ISpecificPageValue[]
): SetSpecificPageValues => ({
  type: SET_SPECIFIC_PAGE_VALUES,
  payload: { specificPageValues },
});

export const setIsLoadingSpecificPageValuesInProgress = (
  isLoadingSpecificPageValuesInProgress: boolean
): SetIsLoadingSpecificPageValuesInProgress => ({
  type: SET_IS_LOADING_SPECIFIC_PAGE_VALUES_IN_PROGRESS,
  payload: { isLoadingSpecificPageValuesInProgress },
});

export const setIsRebuildWithNewContentInProgress = (
  isRebuildWithNewContentInProgress: boolean
): SetIsRebuildWithNewContentInProgress => ({
  type: SET_IS_REBUILD_WITH_NEW_CONTENT_IN_PROGRESS,
  payload: { isRebuildWithNewContentInProgress },
});

export const setIsUpdSpecificPageValuesInProgress = (
  isUpdSpecificPageValuesInProgress: boolean
): SetIsUpdSpecificPageValuesInProgress => ({
  type: SET_IS_UPDATING_SPECIFIC_PAGE_VALUES_IN_PROGRESS,
  payload: { isUpdSpecificPageValuesInProgress },
});

export const setIsDeployRequestInProgress = (
  isDeployRequestInProgress: boolean
): SetIsDeployRequestInProgress => ({
  type: IS_DEPLOY_REQUEST_IN_PROGRESS,
  payload: { isDeployRequestInProgress },
});

export const setIsDeletionInProgress = (
  isDeletionInProgress: boolean
): SetIsDeletionInProgress => ({
  type: SET_IS_DELETION_IN_PROGRESS,
  payload: { isDeletionInProgress },
});

export const setInProgressSiteId = (
  inProgressId: null | string
): SetInProgressSiteId => ({
  type: SET_IN_PROGRESS_SITE_ID,
  payload: { inProgressId },
});

export const setIsWritingArticleInProgress = (
  isWritingArticleInProgress: boolean
): SetIsWritingArticleInProgress => ({
  type: SET_IS_WRITING_ARTICLE_IN_PROGRESS,
  payload: { isWritingArticleInProgress },
});

export type SiteThunkType = ThunkAction<
  Promise<void>,
  AppStateType,
  unknown,
  SiteActionsTypes
>;

export const getSites = (): SiteThunkType => async (dispatch, getState) => {
  try {
    const sortBy = getState().site.sortBy;
    const sortByField = getState().site.sortByField;
    const page = getState().site.page;
    const serchByName = getState().site.serchByName;
    const serchByState = getState().site.serchByState;
    const serchByCity = getState().site.serchByCity;
    const serchByZip = getState().site.serchByZip;
    const serchByDomain = getState().site.serchByDomain;
    const serchByCompany = getState().site.serchByCompany;
    const serchByStatus = getState().site.serchByStatus;
    const serchByCounty = getState().site.serchByCounty;
    const serchByError = getState().site.serchByError;
    const searchByInProgress = getState().site.searchByInProgress;
    dispatch(setIsGettingSitesInProgress(true));
    const response = await siteAPI.loadSites(
      sortBy,
      sortByField,
      page,
      serchByName,
      serchByState,
      serchByCity,
      serchByZip,
      serchByDomain,
      serchByCompany,
      serchByStatus,
      serchByCounty,
      serchByError,
      searchByInProgress
    );
    if (response.status === "success") {
      dispatch(setSites(response.sites));
      dispatch(setSitesTotal(response.totalCount));
    }
  } catch (e) {
    notification.error({
      message: "Error",
      description: "Error loading list of sites",
    });
  } finally {
    dispatch(setIsGettingSitesInProgress(false));
  }
};

export const getOneSite =
  (id: string): SiteThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsGettingSitesInProgress(true));
      const response = await siteAPI.loadOneSite(id);
      if (response.status === "success") {
        dispatch(setSite(response.site));
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error loading site",
      });
    } finally {
      dispatch(setIsGettingSitesInProgress(false));
    }
  };

export const createSite =
  (
    company: string,
    state: string,
    county: string,
    city: string,
    zip: string,
    form: FormInstance<any> | undefined,
    setIsModalOpen: (param: boolean) => void
  ): SiteThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsCreatingSiteInProgress(true));
      const response = await siteAPI.newSite(
        company,
        state,
        county,
        city || "",
        zip || "",
        "false"
      );
      console.log("response", response);
      if (response.status === "success") {
        dispatch(getSites());
        notification.success({
          message: "Success",
          description: "New sites successfully created",
        });
        form?.resetFields();
        setIsModalOpen(false);
      }
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Error",
        description: "Error while creating sites",
      });
    } finally {
      dispatch(setIsCreatingSiteInProgress(false));
    }
  };

export const updateSite =
  (
    values: { [param: string]: string | boolean },
    // error: boolean,
    // statusAutoProgressOnCreate: boolean,
    siteId: string
  ): SiteThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsUpdatingSiteInProgress(true));
      const response = await siteAPI.updateSite(values, siteId);

      if (response.status === "success") {
        dispatch(getOneSite(siteId));
        notification.success({
          message: "Success",
          description: "Site updated successfully",
        });
      }
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Error",
        description: "Error while updating site",
      });
    } finally {
      dispatch(setIsUpdatingSiteInProgress(false));
    }
  };

export const updateManySites =
  (
    values: { [param: string]: string | boolean },
    sitesIds: string[]
  ): SiteThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsUpdatingSiteInProgress(true));
      const response = await siteAPI.updateManySites(values, sitesIds);

      if (response.status === "success") {
        dispatch(getSites());
        notification.success({
          message: "Success",
          description: "Sites updated successfully",
        });
      }
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Error",
        description: "Error while updating sites",
      });
    } finally {
      dispatch(setIsUpdatingSiteInProgress(false));
    }
  };

export const deleteField =
  (fieldName: string, siteId: string): SiteThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsUpdatingSiteInProgress(true));
      const response = await siteAPI.deleteField(fieldName, siteId);

      if (response.status === "success") {
        notification.success({
          message: "Success",
          description: "Site updated successfully",
        });
      }
      dispatch(getOneSite(siteId));
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Error",
        description: "Error while updating site",
      });
    } finally {
      dispatch(setIsUpdatingSiteInProgress(false));
    }
  };

export const getSpecificPageValues =
  (siteId: string): SiteThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsLoadingSpecificPageValuesInProgress(true));
      const response = await siteAPI.loadSpecificPageValues(siteId);

      if (response.status === "success") {
        console.log("SpecificPageValues resp", response);
        dispatch(setSpecificPageValues(response.values));
      }
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Error",
        description: "Error while getSpecificPageValues",
      });
    } finally {
      dispatch(setIsLoadingSpecificPageValuesInProgress(false));
    }
  };

export const deleteSpecificPageValues =
  (siteId: string): SiteThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsLoadingSpecificPageValuesInProgress(true));
      const response = await siteAPI.deleteSpecificPageValues(siteId);

      if (response.status === "success") {
        notification.success({
          message: "Success",
          description: "Deleted",
        });
        dispatch(getSpecificPageValues(siteId));
      }
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Error",
        description: "Error while deleteSpecificPageValues",
      });
    } finally {
      dispatch(setIsLoadingSpecificPageValuesInProgress(false));
    }
  };

export const updSpecificPageValues =
  (
    siteId: string,
    valuesToUpdate: { [param: string]: string }
  ): SiteThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsUpdSpecificPageValuesInProgress(true));
      const response = await siteAPI.updateSpecificPageValues(valuesToUpdate);

      if (response.status === "success") {
        dispatch(getSpecificPageValues(siteId));
      }
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Error",
        description: "Error while updSpecificPageValues",
      });
    } finally {
      dispatch(setIsUpdSpecificPageValuesInProgress(false));
    }
  };

export const deleteSites =
  (siteIds: string[]): SiteThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsDeletionInProgress(true));
      const response = await siteAPI.removeSites(siteIds);

      if (response.status === "success") {
        dispatch(getSites());
      }
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Error",
        description: "Error while deleteSites",
      });
    } finally {
      dispatch(setIsDeletionInProgress(false));
    }
  };

export const rebuildWithNewContent =
  (siteId: string): SiteThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsRebuildWithNewContentInProgress(true));
      const tagsResponse = await tagsApi.loadAllTags();

      if (tagsResponse.status === "success") {
        const tags = tagsResponse?.tags;
        const response = await siteAPI.deleteSpecificPageValues(siteId);
        if (response.status === "success") {
          const obj = {} as { [param: string]: string };
          tags.forEach((t) => {
            if (
              t.fieldInDb !== "companyLocalPhone" &&
              t.fieldInDb !== "companyLocalName" &&
              t.fieldInDb !== "companyLocalPhoneFormatted"
            ) {
              obj[t.fieldInDb] = "";
            }
          });
          await dispatch(
            updateSite(
              {
                status: "initial",
                isInProgress: false,
                error: false,
                htmlBuildLocation: "",
                isDeployed: false,
                ...obj,
              },
              siteId
            )
          );

          notification.success({
            message: "Success",
            description: "Deleted",
          });
        }
      }
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Error",
        description: "Error while deleteSpecificPageValues",
      });
    } finally {
      dispatch(setIsRebuildWithNewContentInProgress(false));
    }
  };

export const deploySite =
  (siteId: string): SiteThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsDeployRequestInProgress(true));
      const response = await siteAPI.deploy(siteId);

      if (response.status === "success") {
        notification.success({
          message: "Success",
          description: "Deploing is started",
        });
      }
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Error",
        description: "Error while deploySite",
      });
    } finally {
      dispatch(setIsDeployRequestInProgress(false));
    }
  };

export const writeArticle =
  (siteId: string, keyword: string, isReview = false): SiteThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsWritingArticleInProgress(true));
      const response = await siteAPI.writeArticleAndBlogDeploy(
        siteId,
        keyword,
        isReview
      );

      if (response.status === "success") {
        notification.success({
          message: "Success",
          description: "Article created",
        });
      }
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Error",
        description: "Error while creatin article",
      });
    } finally {
      dispatch(setIsWritingArticleInProgress(false));
    }
  };
