import React, { Dispatch, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CallsThunkType, getCalls } from "../store/actions/callsActions";
import { AppStateType } from "../store";

export const Calls = () => {
  const dispatch = useDispatch() as Dispatch<CallsThunkType>;

  const calls = useSelector((state: AppStateType) => state.calls.calls);
  const isCallsLoadingInProgress = useSelector(
    (state: AppStateType) => state.calls.isCallsLoadingInProgress
  );

  useEffect(() => {
    dispatch(getCalls());
  }, []);

  return (
    <div style={{ padding: 10 }}>
      <div>{isCallsLoadingInProgress && "Loading..."}</div>
      {calls && JSON.stringify(calls)}
    </div>
  );
};
