import axios from "axios";
import {
  ICompany,
  ICreateCompanyResponse,
  ICreateSiteResponse,
  ICreateTagsResponse,
  IDomain,
  IGetAppliancesResponse,
  IGetBrandsResponse,
  IGetCallrailCompanyResponse,
  IGetCategoriesResponse,
  IGetCitiesResponse,
  IGetCompaniesResponse,
  IGetCompanyResponse,
  IGetCountiesResponse,
  IGetLogsResponse,
  IGetOneTagResponse,
  IGetOneTemplateResponse,
  IGetSiteResponse,
  IGetSitesResponse,
  IGetSpecificPagePromt,
  IGetStatesResponse,
  IGetTagsResponse,
  IGetTemplateTypesResponse,
  IGetTemplatesResponse,
  ILoadSpecificPageValues,
  INewSpecificPagePromt,
  ITag,
  IUpdateCompany,
  IUsersResponse,
  IgetDomainsResponse,
  IloginResponse,
} from "./types";
import { mainUrl } from "./helpers/constants";

const instance = axios.create({
  baseURL: mainUrl,
  headers: { "Content-Type": "application/json" },
});

export const authAPI = {
  login: async (email: string, password: string) => {
    const res = await instance.post<IloginResponse>(`api/v1/auth/login`, {
      email,
      password,
    });
    return res.data;
  },
  checkToken: async (token: string) => {
    const res = await instance.post<IloginResponse>(`api/v1/auth/me`, {
      token,
    });
    return res.data;
  },
  loadUsers: async () => {
    const token = localStorage.getItem("token");
    const res = await instance.get<IUsersResponse>(`api/v1/auth/users`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  },
};

export const siteAPI = {
  loadSites: async (
    sortBy: string,
    sortByField: string,
    page: number,
    serchByName: string,
    serchByState: string,
    serchByCity: string,
    serchByZip: string,
    serchByDomain: string,
    serchByCompany: string,
    serchByStatus: string,
    serchByCounty: string,
    serchByError: string,
    searchByInProgress: string
  ) => {
    const token = localStorage.getItem("token");
    const res = await instance.get<IGetSitesResponse>(
      `api/v1/site?sortBy=${sortBy}&sortByField=${sortByField}&page=${page}&serchByName=${serchByName}&serchByState=${serchByState}&serchByCity=${serchByCity}&serchByZip=${serchByZip}&serchByDomain=${serchByDomain}&serchByCompany=${serchByCompany}&serchByStatus=${serchByStatus}&serchByCounty=${serchByCounty}&serchByError=${serchByError}&searchByInProgress=${searchByInProgress}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  loadOneSite: async (id: string) => {
    const token = localStorage.getItem("token");
    const res = await instance.get<IGetSiteResponse>(`api/v1/site/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  },
  newSite: async (
    company: string,
    state: string,
    county: string,
    city: string,
    zip: string,
    statusAutoProgressOnCreate: string
  ) => {
    const token = localStorage.getItem("token");
    const res = await instance.post<ICreateSiteResponse>(
      `api/v1/site`,
      {
        company,
        state,
        county,
        city,
        filterZip: zip,
        statusAutoProgressOnCreate,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  updateSite: async (
    values: { [param: string]: string | boolean },
    // error: boolean,
    // statusAutoProgressOnCreate: boolean,
    siteId: string
  ) => {
    const token = localStorage.getItem("token");
    const res = await instance.put<ICreateSiteResponse>(
      `api/v1/site/${siteId}`,
      {
        ...values,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  updateManySites: async (
    values: { [param: string]: string | boolean },
    sitesIds: string[]
  ) => {
    const token = localStorage.getItem("token");
    const res = await instance.post<ICreateSiteResponse>(
      `api/v1/site/update-many/`,
      {
        ids: sitesIds,
        update: values,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  deleteField: async (fieldName: string, siteId: string) => {
    const token = localStorage.getItem("token");
    const res = await instance.put<ICreateSiteResponse>(
      `api/v1/site/${siteId}`,
      {
        //[fieldName]: "",
        [fieldName]: null,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  loadSpecificPageValues: async (siteId: string) => {
    const token = localStorage.getItem("token");
    const res = await instance.get<ILoadSpecificPageValues>(
      `api/v1/specific-page-promt-value/${siteId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  deleteSpecificPageValues: async (siteId: string) => {
    const token = localStorage.getItem("token");
    const res = await instance.delete<ILoadSpecificPageValues>(
      `api/v1/specific-page-promt-value/all/${siteId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  deleteManySpecificPageValuesBySitesIds: async (siteIds: string[]) => {
    const token = localStorage.getItem("token");
    const res = await instance.post<ILoadSpecificPageValues>(
      `api/v1/specific-page-promt-value/delete/allBySitesIds`,
      {
        ids: siteIds,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  updateSpecificPageValues: async (valuesToUpdate: {
    [param: string]: string;
  }) => {
    const token = localStorage.getItem("token");
    const res = await instance.patch<ILoadSpecificPageValues>(
      `api/v1/specific-page-promt-value`,
      valuesToUpdate,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  removeSites: async (siteIds: string[]) => {
    const token = localStorage.getItem("token");
    const res = await instance.post<ICreateSiteResponse>(
      `api/v1/site/delete`,
      {
        siteIds,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  deploy: async (siteId: string) => {
    const token = localStorage.getItem("token");
    const res = await instance.post<IGetSitesResponse>(
      `api/v1/site/deploy`,
      {
        siteId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  writeArticleAndBlogDeploy: async (
    siteId: string,
    keyword: string,
    isReview: boolean
  ) => {
    const token = localStorage.getItem("token");
    const res = await instance.post<any>(
      `api/v1/site/write-article`,
      {
        siteId,
        keyword,
        type: isReview ? "review" : undefined,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
};
export const locationAPI = {
  loadStates: async () => {
    const res = await instance.get<IGetStatesResponse>(`api/v1/state`);
    return res.data;
  },
  loadCitiesByState: async (state: string) => {
    const res = await instance.get<IGetCitiesResponse>(`api/v1/city/${state}`);
    return res.data;
  },
  loadCountiesByFips: async (fips: number) => {
    const res = await instance.get<IGetCountiesResponse>(
      `api/v1/county/${fips}`
    );
    return res.data;
  },
  loadCountiesByState: async (state: string) => {
    const res = await instance.get<IGetCountiesResponse>(
      `api/v1/county/bystate/${state}`
    );
    return res.data;
  },
  loadCitiesByCounty: async (countyFips: string) => {
    const res = await instance.get<IGetCitiesResponse>(
      `api/v1/city/bycounty/${countyFips}`
    );
    return res.data;
  },
  loadCitiesByPartName: async (value: string) => {
    const res = await instance.get<any>(`api/v1/city/sug?q=${value}`);
    return res.data;
  },
};

export const applianceApi = {
  loadAppliances: async () => {
    const res = await instance.get<IGetAppliancesResponse>(`api/v1/appliance`);
    return res.data;
  },
  updAppliance: async (
    applianceId: string,
    values: { [param: string]: string }
  ) => {
    const token = localStorage.getItem("token");
    const res = await instance.put<IGetAppliancesResponse>(
      `api/v1/appliance/${applianceId}`,
      { ...values },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  createAppliance: async (values: { [param: string]: string }) => {
    const token = localStorage.getItem("token");
    const res = await instance.post<IGetAppliancesResponse>(
      `api/v1/appliance`,
      { ...values },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  loadAppliancesByCategory: async (categoryId: string) => {
    const res = await instance.get<IGetAppliancesResponse>(
      `api/v1/appliance/category/${categoryId}`
    );
    return res.data;
  },
  loadBrands: async () => {
    const res = await instance.get<IGetBrandsResponse>(
      `api/v1/brand-appliance`
    );
    return res.data;
  },
  loadBrandsBySubcategory: async (subCategoryId: string) => {
    const res = await instance.get<IGetBrandsResponse>(
      `api/v1/brand-appliance/subcategory/${subCategoryId}`
    );
    return res.data;
  },
  loadAllBrandAppliances: async () => {
    const res = await instance.get<IGetBrandsResponse>(
      `api/v1/brand-appliance/all`
    );
    return res.data;
  },
};

export const settingsApi = {
  loadCategories: async () => {
    const res = await instance.get<IGetCategoriesResponse>(`api/v1/category`);
    return res.data;
  },
  createCategory: async (name: string, type: string) => {
    const token = localStorage.getItem("token");
    const res = await instance.post<IGetCategoriesResponse>(
      `api/v1/category/new`,
      { name, type },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  upateCategory: async (
    values: { [param: string]: string },
    categoryId: string
  ) => {
    const token = localStorage.getItem("token");
    const res = await instance.put<IGetCategoriesResponse>(
      `api/v1/category/${categoryId}`,
      { ...values },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  newBrandAppliance: async (applianceId: string, brandsIds: string[]) => {
    const token = localStorage.getItem("token");
    const res = await instance.post<{
      status: string;
    }>(
      `api/v1/category`,
      { applianceId, brandsIds },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
};

export const domainApi = {
  getDomainsByCategory: async (categoryId: string) => {
    const token = localStorage.getItem("token");
    const res = await instance.get<IgetDomainsResponse>(
      `api/v1/domain/${categoryId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  getStatistics: async (
    activeDomain: string,
    startDate: string,
    finishDate: string
  ) => {
    const token = localStorage.getItem("token");
    const res = await instance.post<any>(
      `api/v1/domain/search-analytics/`,
      {
        siteUrl: activeDomain,
        startDate: startDate,
        endDate: finishDate,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  createDomain: async (domain: IDomain) => {
    const token = localStorage.getItem("token");
    const res = await instance.post<IgetDomainsResponse>(
      `api/v1/domain/`,
      { ...domain },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  updateDomain: async (
    domain: { [param: string]: string },
    domainId: string
  ) => {
    const token = localStorage.getItem("token");
    const res = await instance.put<IgetDomainsResponse>(
      `api/v1/domain/${domainId}`,
      { ...domain },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  getAllStatistics: async (
    onlyClicks = false,
    startDate = "",
    endDate = ""
  ) => {
    const token = localStorage.getItem("token");
    const res = await instance.post<any>(
      `api/v1/domain/all-search-analytics`,
      { onlyClicks, startDate, endDate },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
};

export const companyApi = {
  newCompany: async (name: string) => {
    const token = localStorage.getItem("token");
    const res = await instance.post<ICreateCompanyResponse>(
      `api/v1/company`,
      { name },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  updCompany: async (company: IUpdateCompany) => {
    const token = localStorage.getItem("token");
    const res = await instance.put<ICreateCompanyResponse>(
      `api/v1/company/${company._id}`,
      { ...company, _id: undefined },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  updCompanyTrade: async (
    checkedCategories: string[],
    checkedAppliances: string[],
    checkedBrandAppliances: string[],
    companyId: string
  ) => {
    const token = localStorage.getItem("token");
    const res = await instance.put<ICreateCompanyResponse>(
      `api/v1/company/trade/${companyId}`,
      { checkedCategories, checkedAppliances, checkedBrandAppliances },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  loadCompanies: async () => {
    const token = localStorage.getItem("token");
    const res = await instance.get<IGetCompaniesResponse>(`api/v1/company`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  },
  loadCompany: async (id: string) => {
    const token = localStorage.getItem("token");
    const res = await instance.get<IGetCompanyResponse>(
      `api/v1/company/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  loadCallrailCompanies: async () => {
    const token = localStorage.getItem("token");
    const res = await instance.get<IGetCallrailCompanyResponse>(
      `api/v1/company/callrail-companies/active`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
};

export const templatesApi = {
  loadTemplates: async (
    page: number,
    type: string,
    palette: string,
    id: string
  ) => {
    const token = localStorage.getItem("token");
    const res = await instance.get<IGetTemplatesResponse>(
      `api/v1/template/${page}/${type}/${palette}/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  loadTemplateTypes: async () => {
    const token = localStorage.getItem("token");
    const res = await instance.get<IGetTemplateTypesResponse>(
      `api/v1/template/types`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  loadOneTemplate: async (id: string) => {
    const token = localStorage.getItem("token");
    const res = await instance.get<IGetOneTemplateResponse>(
      `api/v1/template/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  updateTemplate: async (
    template: {
      styles: string;
      html: string;
      script: string;
    },
    templateId: string
  ) => {
    const token = localStorage.getItem("token");
    const res = await instance.put<IGetOneTemplateResponse>(
      `api/v1/template/${templateId}`,
      { ...template },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  removeTemplate: async (templateId: string) => {
    const token = localStorage.getItem("token");
    const res = await instance.delete<IGetOneTemplateResponse>(
      `api/v1/template/${templateId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
};

export const logsApi = {
  loadLogs: async (page: number) => {
    const token = localStorage.getItem("token");
    const res = await instance.get<IGetLogsResponse>(
      `api/v1/log?page=${page}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
};

export const tagsApi = {
  loadTags: async (page: number) => {
    const token = localStorage.getItem("token");
    const res = await instance.get<IGetTagsResponse>(
      `api/v1/tag?page=${page}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  loadAllTags: async () => {
    const token = localStorage.getItem("token");
    const res = await instance.get<IGetTagsResponse>(`api/v1/tag/all`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  },
  loadOneTag: async (id: string) => {
    const token = localStorage.getItem("token");
    const res = await instance.get<IGetOneTagResponse>(`api/v1/tag/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  },
  addTag: async (
    tagName: string,
    fieldInDb: string,
    isGenerateByAi: boolean,
    aiPromt?: string,
    isImage?: boolean
  ) => {
    const token = localStorage.getItem("token");
    const res = await instance.post<ICreateTagsResponse>(
      `api/v1/tag`,
      { tagName, fieldInDb, isGenerateByAi, aiPromt, isImage },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  updateOneTag: async (id: string, tag: ITag) => {
    const token = localStorage.getItem("token");
    const res = await instance.put<IGetOneTagResponse>(
      `api/v1/tag/${id}`,
      { ...tag },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  newSpecificPagePromt: async (promt: string, page: string, id: string) => {
    const token = localStorage.getItem("token");
    const res = await instance.post<INewSpecificPagePromt>(
      `api/v1/specific-page-promt`,
      { promt, page, tag: id },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  updSpecificPagePromts: async (specificPageUpdateField: {
    [param: string]: string;
  }) => {
    const token = localStorage.getItem("token");
    const res = await instance.patch<INewSpecificPagePromt>(
      `api/v1/specific-page-promt`,
      { ...specificPageUpdateField },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  removeSpecificPagePromt: async (id: string) => {
    const token = localStorage.getItem("token");
    const res = await instance.delete<INewSpecificPagePromt>(
      `api/v1/specific-page-promt/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  getSpecificPagePromts: async (id: string) => {
    const token = localStorage.getItem("token");
    const res = await instance.get<IGetSpecificPagePromt>(
      `api/v1/specific-page-promt/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
};

export const imagesApi = {
  loadImages: async (page: number, category: string, user: string) => {
    const token = localStorage.getItem("token");
    const res = await instance.get<any>(
      `api/v1/image/${page}/${category}/${user}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  loadSiteImages: async (siteId: string) => {
    const token = localStorage.getItem("token");
    const res = await instance.get<any>(`api/v1/image/site-image/${siteId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  },
  deleteImage: async (imageId: string) => {
    const token = localStorage.getItem("token");
    const res = await instance.delete<any>(`api/v1/image/${imageId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  },
  deleteOneSiteImage: async (imageId: string) => {
    const token = localStorage.getItem("token");
    const res = await instance.delete<any>(`api/v1/image/site/one/${imageId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  },
  deleteAllSiteImages: async (siteId: string) => {
    const token = localStorage.getItem("token");
    const res = await instance.delete<any>(`api/v1/image/site/all/${siteId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  },
};

export const callsApi = {
  loadCalls: async () => {
    const token = localStorage.getItem("token");
    const res = await instance.get<any>(`api/v1/bulkvs/calls`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  },
};
