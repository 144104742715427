export const getBeautifullDate = (date: string) => {
  return `${new Date(Date.parse(date)).toLocaleDateString("en-US")} ${
    new Date(Date.parse(date)).toLocaleTimeString("en-US")?.split(":")[0]
  }:${
    new Date(Date.parse(date))
      .toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
      ?.split(":")[1]
  }`;
};

export function isValidDomain(domain: string) {
  const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,}$/;
  return domainRegex.test(domain);
}

export function timeAgo(dateString: string): string {
  const inputDate = new Date(dateString);
  const now = new Date();

  // Проверка на валидность даты
  if (isNaN(inputDate.getTime())) {
    throw new Error("Invalid date format");
  }

  const differenceInMs = now.getTime() - inputDate.getTime();
  const seconds = Math.floor(differenceInMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30); // Условная длина месяца
  const years = Math.floor(days / 365);

  if (seconds < 60) {
    return `${seconds} seconds ago`;
  } else if (minutes < 60) {
    return `${minutes} minutes ago`;
  } else if (hours < 24) {
    return `${hours} hours ago`;
  } else if (days < 7) {
    return `${days} days ago`;
  } else if (weeks < 4) {
    return `${weeks} weeks ago`;
  } else if (months < 12) {
    return `${months} months ago`;
  } else {
    return `${years} years ago`;
  }
}

export function getFirstDayOfPreviousMonth(currentDate = new Date()) {
  const currentMonthIndex = currentDate.getMonth(); // месяцы: 0 (январь) - 11 (декабрь)
  let year = currentDate.getFullYear();
  let prevMonthIndex;

  if (currentMonthIndex === 0) {
    // Если текущий месяц январь, то предыдущий месяц — декабрь прошлого года
    year--;
    prevMonthIndex = 11; // декабрь (11 в 0-индексации)
  } else {
    prevMonthIndex = currentMonthIndex - 1;
  }

  // Форматируем месяц в человекочитаемый вид (от 1 до 12) с ведущим нулём, если необходимо
  const monthStr = String(prevMonthIndex + 1).padStart(2, "0");
  return `${year}-${monthStr}-01`;
}

export function getLastDayOfCurrentMonth(currentDate = new Date()) {
  const year = currentDate.getFullYear();
  const monthIndex = currentDate.getMonth(); // 0 (январь) - 11 (декабрь)

  // Для получения последнего дня месяца создаём дату следующего месяца с днем 0.
  const lastDay = new Date(year, monthIndex + 1, 0).getDate();

  // Форматируем месяц и день с ведущим нулём при необходимости
  const monthStr = String(monthIndex + 1).padStart(2, "0");
  const dayStr = String(lastDay).padStart(2, "0");

  return `${year}-${monthStr}-${dayStr}`;
}
