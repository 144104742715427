import { useEffect, useState } from "react";
import axios from "axios";
import { Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
import { CitySelect } from "../components/stats/citySelect";

export interface FormData {
  companyId?: string;
  startDate: string;
  endDate: string;
  url?: string;
  cityId?: string;
  zip?: string;
  countyId?: string;
  stateId?: string;
  name?: string;
}

interface QueryData {
  query: string;
  url: string;
  clicks: number;
  impressions: number;
  ctr: number;
  position: number;
  company: string;
  siteId: string;
  date?: string;
}

interface StatData {
  _id: string;
  date: string;
  totalClicks: number;
  totalImpressions: number;
  averageCtr: number;
  averagePosition: number;
  topQueries: QueryData[];
}

export function Clicks() {
  const token = localStorage.getItem("token");
  const [formData, setFormData] = useState<FormData>({
    startDate: "",
    endDate: "",
  });
  const [stats, setStats] = useState<StatData[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [allQueries, setAllQueries] = useState<QueryData[]>([]);

  function addOneDay(dateStr: string) {
    // Добавляем время, чтобы избежать проблем с часовыми поясами
    const date = new Date(dateStr + "T00:00:00");

    // Добавляем один день
    date.setDate(date.getDate() + 1);

    // Получаем год, месяц и день с нужным форматированием
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // месяцы начинаются с 0!
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      [
        "url",
        "cityId",
        "zip",
        "countyId",
        "stateId",
        "companyId",
        "name",
        "startDate",
        "endDate",
      ].includes(e.target.name)
    ) {
      setFormData((prev) => ({
        startDate: prev.startDate,
        endDate: prev.endDate,
        [e.target.name]: e.target.value,
      }));
    }
    // } else {
    //   setFormData({ ...formData, [e.target.name]: e.target.value });
    // }
    //setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const fetchStats = async () => {
    setFormData((prev) => {
      const { startDate, endDate, ...filters } = prev;
      const filterKeys = Object.keys(filters).filter(
        (key) => filters[key as keyof typeof filters]
      );
      if (filterKeys.length > 1) {
        setError("Only one secondary filter can be applied");
        return prev;
      }
      return prev;
    });
    try {
      setError(null);
      const { startDate, endDate, ...filters } = formData;
      console.log("startDate", startDate);
      console.log("converted startDate", startDate);
      let extDate = new Date(startDate);
      let utcDate = new Date(
        extDate.getTime() + extDate.getTimezoneOffset() * 60000
      );
      console.log(utcDate);

      if (!startDate || !endDate) {
        setError("startDate and endDate are required");
        return;
      }

      const filterKeys = Object.keys(filters).filter(
        (key) => filters[key as keyof typeof filters]
      );
      if (filterKeys.length > 1) {
        setError("Only one secondary filter can be applied");
        return;
      }

      if (formData.companyId) {
        filterKeys.push("companyId");
      }

      const response = await axios.post<{ status: string; stats: StatData[] }>(
        "https://sitegen-back.localigniter.com/api/v1/domain/agregated-stats",
        { ...formData, endDate: addOneDay(formData.endDate) },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setStats(
        response.data.stats
          .sort(
            (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
          )
          .map((s) => ({
            ...s,
            date: new Date(s.date).toLocaleDateString(),
          }))
      );
    } catch (err: any) {
      setError(err.response?.data?.message || "Error fetching stats");
    }
  };

  useEffect(() => {
    let aStats: QueryData[] = [];
    if (stats) {
      stats.forEach((stat) => {
        let edited: QueryData[] = [];
        stat.topQueries.forEach((q) => {
          edited.push({ ...q, date: stat.date });
        });

        aStats = [...aStats, ...edited];
      });
    }
    setAllQueries(aStats);
  }, [stats]);

  return (
    <div style={{ padding: 10 }} className="p-4">
      <h1 className="text-xl font-bold mb-4">Aggregated Stats</h1>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div>
          <h3>Start date</h3>
          <input
            style={{ height: 40 }}
            type="date"
            name="startDate"
            value={formData.startDate}
            onChange={handleChange}
            className="border p-2"
          />
        </div>
        <div>
          <h3>End date</h3>
          <input
            style={{ height: 40 }}
            type="date"
            name="endDate"
            value={formData.endDate}
            onChange={handleChange}
            className="border p-2"
          />
        </div>
        <div>
          <h3>Domain</h3>
          <input
            style={{ height: 40 }}
            type="text"
            name="url"
            placeholder="domain.com"
            value={formData.url || ""}
            onChange={handleChange}
            className="border p-2"
          />
        </div>
        <CitySelect setFormData={setFormData} formData={formData} />
        <div>
          <h3>Zip</h3>
          <input
            style={{ height: 40 }}
            type="text"
            name="zip"
            placeholder="ZIP"
            value={formData.zip || ""}
            onChange={handleChange}
            className="border p-2"
          />
        </div>
        <div>
          <h3>County ID</h3>
          <input
            style={{ height: 40 }}
            type="text"
            name="countyId"
            placeholder="County ID"
            value={formData.countyId || ""}
            onChange={handleChange}
            className="border p-2"
          />
        </div>
        <div>
          <h3>State ID</h3>
          <input
            style={{ height: 40 }}
            type="text"
            name="stateId"
            placeholder="State ID"
            value={formData.stateId || ""}
            onChange={handleChange}
            className="border p-2"
          />
        </div>
        <div>
          <h3>Company ID</h3>
          <input
            style={{ height: 40 }}
            type="text"
            name="companyId"
            placeholder="Company ID"
            value={formData.companyId || ""}
            onChange={handleChange}
            className="border p-2"
          />
        </div>
        <div>
          <h3>Site name</h3>
          <input
            style={{ height: 40 }}
            type="text"
            name="name"
            placeholder="e.g. 703-Coronado-92118"
            value={formData.name || ""}
            onChange={handleChange}
            className="border p-2"
          />
        </div>
        <button
          style={{ height: 45, marginTop: 60 }}
          onClick={fetchStats}
          className="bg-blue-500 text-white p-2 rounded"
        >
          Fetch Stats
        </button>
      </div>

      {error && <p style={{ color: "red" }}>{error}</p>}
      {stats && (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "16px",
            marginTop: "24px",
          }}
        >
          <div style={{ width: "100%", height: "256px" }}>
            <h2 className="text-lg font-bold">Total Clicks</h2>
            <LineChart width={700} height={200} data={stats}>
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Line type="monotone" dataKey="totalClicks" stroke="#8884d8" />
            </LineChart>
          </div>
          <div className="w-full h-64">
            <h2 className="text-lg font-bold">Total Impressions</h2>
            <LineChart width={700} height={200} data={stats}>
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="totalImpressions"
                stroke="#82ca9d"
              />
            </LineChart>
          </div>
          <div className="w-full h-64">
            <h2 className="text-lg font-bold">Average Ctr</h2>
            <LineChart width={700} height={200} data={stats}>
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Line type="monotone" dataKey="averageCtr" stroke="#ff7300" />
            </LineChart>
          </div>
          <div className="w-full h-64">
            <h2 className="text-lg font-bold">Average Position</h2>
            <LineChart width={700} height={200} data={stats}>
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="averagePosition"
                stroke="#d84315"
              />
            </LineChart>
          </div>
        </div>
      )}
      {stats && (
        <table
          style={{
            width: "100%",
            marginTop: "16px",
            borderCollapse: "collapse",
          }}
        >
          <thead>
            <tr style={{ borderBottom: "2px solid black" }}>
              <th style={{ textAlign: "left", padding: 10 }}>Date</th>
              <th style={{ textAlign: "left", padding: 10 }}>URL</th>
              <th style={{ textAlign: "left", padding: 10 }}>Query</th>
              <th style={{ textAlign: "left", padding: 10 }}>Clicks</th>
              <th style={{ textAlign: "left", padding: 10 }}>Impressions</th>
              <th style={{ textAlign: "left", padding: 10 }}>CTR</th>
              <th style={{ textAlign: "left", padding: 10 }}>Position</th>
            </tr>
          </thead>
          <tbody>
            {allQueries
              .sort((a, b) => b.clicks - a.clicks || a.position - b.position)
              .map((query, index) => (
                <tr key={index} style={{ borderBottom: "1px solid gray" }}>
                  <td style={{ padding: 10 }}>
                    {/* @ts-ignore */}
                    {new Date(query?.date).toLocaleDateString()}
                  </td>
                  <td style={{ padding: 10 }}>{query.url}</td>
                  <td
                    style={{
                      padding: 10,
                      fontWeight: query.clicks > 0 ? "bold" : undefined,
                    }}
                  >
                    {query.query}
                  </td>
                  <td style={{ padding: 10 }}>{query.clicks}</td>
                  <td style={{ padding: 10 }}>{query.impressions}</td>
                  <td style={{ padding: 10 }}>
                    {(query.ctr * 100).toFixed(2)}%
                  </td>
                  <td style={{ padding: 10 }}>{query.position}</td>
                </tr>
              ))}
          </tbody>
        </table>
      )}

      {stats && (
        <div className="mt-4">
          <h2 className="text-lg font-bold">Results:</h2>
          <pre className="bg-gray-100 p-4 rounded">
            {JSON.stringify(stats, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
}
