import { ICall } from "../../types";
import { CallsActionsTypes } from "../actions/callsActions";

export const SET_CALLS = "calls/SET_CALLS";
export const SET_IS_CALLS_LOADING_IN_PROGRESS =
  "calls/SET_IS_CALLS_LOADING_IN_PROGRESS";

const initialState = {
  calls: [] as ICall[],
  isCallsLoadingInProgress: false,
};

type InitialStateType = typeof initialState;

export const callsReducer = (
  state = initialState,
  action: CallsActionsTypes
): InitialStateType => {
  switch (action.type) {
    case SET_CALLS:
    case SET_IS_CALLS_LOADING_IN_PROGRESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
