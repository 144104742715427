import { ThunkAction } from "redux-thunk";
import { AppStateType } from "..";
import {
  SET_IS_GETTING_STATES_IN_PROGRESS,
  SET_STATES,
  SET_IS_GETTING_CITY_BY_STATE_IN_PROGRESS,
  SET_CITIES,
  SET_COUNTIES,
  SET_IS_GETTING_COUNTIES_IN_PROGRESS,
  SET_IS_GETTING_CITIES_BY_PART_NAME_IN_PROGRESS,
  SET_CITIES_SEGGESTIONS,
} from "../reducers/locationReducer";
import { notification } from "antd";
import { locationAPI } from "../../api";
import { ICity, ICounty, IState } from "../../types";

type SetIsGettingStatesInProgress = {
  type: typeof SET_IS_GETTING_STATES_IN_PROGRESS;
  payload: { isGettingStatesInProgress: boolean };
};

type SetIsGettingCityByStateInProgress = {
  type: typeof SET_IS_GETTING_CITY_BY_STATE_IN_PROGRESS;
  payload: { isGettingCityByStateInProgress: boolean };
};

type SetIsGettingCountiesInProgress = {
  type: typeof SET_IS_GETTING_COUNTIES_IN_PROGRESS;
  payload: { isGettingCountiesInProgress: boolean };
};

type SetIsGettingCitiesByPartNameInProgress = {
  type: typeof SET_IS_GETTING_CITIES_BY_PART_NAME_IN_PROGRESS;
  payload: { isGettingCitiesByPartNameInProgress: boolean };
};

type SetStates = {
  type: typeof SET_STATES;
  payload: { states: IState[] };
};

type SetCities = {
  type: typeof SET_CITIES;
  payload: { cities: ICity[] };
};

type SetCounties = {
  type: typeof SET_COUNTIES;
  payload: { counties: ICounty[] };
};

type SetCitiesSuggestions = {
  type: typeof SET_CITIES_SEGGESTIONS;
  payload: { citiesSuggestions: any };
};

export type LocationActionsTypes =
  | SetIsGettingStatesInProgress
  | SetStates
  | SetIsGettingCityByStateInProgress
  | SetCities
  | SetCounties
  | SetIsGettingCountiesInProgress
  | SetIsGettingCitiesByPartNameInProgress
  | SetCitiesSuggestions;

export const setIsGettingStatesInProgress = (
  isGettingStatesInProgress: boolean
): SetIsGettingStatesInProgress => ({
  type: SET_IS_GETTING_STATES_IN_PROGRESS,
  payload: { isGettingStatesInProgress },
});

export const setIsGettingCityByStateInProgress = (
  isGettingCityByStateInProgress: boolean
): SetIsGettingCityByStateInProgress => ({
  type: SET_IS_GETTING_CITY_BY_STATE_IN_PROGRESS,
  payload: { isGettingCityByStateInProgress },
});

export const setIsGettingCountiesInProgress = (
  isGettingCountiesInProgress: boolean
): SetIsGettingCountiesInProgress => ({
  type: SET_IS_GETTING_COUNTIES_IN_PROGRESS,
  payload: { isGettingCountiesInProgress },
});

export const setStates = (states: IState[]): SetStates => ({
  type: SET_STATES,
  payload: { states },
});

export const setCities = (cities: ICity[]): SetCities => ({
  type: SET_CITIES,
  payload: { cities },
});

export const setCitiesSuggestions = (
  citiesSuggestions: any
): SetCitiesSuggestions => ({
  type: SET_CITIES_SEGGESTIONS,
  payload: { citiesSuggestions },
});

export const setCounties = (counties: ICounty[]): SetCounties => ({
  type: SET_COUNTIES,
  payload: { counties },
});

export const setIsGettingCitiesByPartNameInProgress = (
  isGettingCitiesByPartNameInProgress: boolean
): SetIsGettingCitiesByPartNameInProgress => ({
  type: SET_IS_GETTING_CITIES_BY_PART_NAME_IN_PROGRESS,
  payload: { isGettingCitiesByPartNameInProgress },
});

export type LocationThunkType = ThunkAction<
  Promise<void>,
  AppStateType,
  unknown,
  LocationActionsTypes
>;

export const getStates =
  (): LocationThunkType => async (dispatch, getState) => {
    try {
      dispatch(setIsGettingStatesInProgress(true));
      const response = await locationAPI.loadStates();

      if (response.status === "success") {
        dispatch(setStates(response.states));
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error loading list of states",
      });
    } finally {
      dispatch(setIsGettingStatesInProgress(false));
    }
  };

export const getCityByState =
  (state: string): LocationThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setCities([]));
      dispatch(setIsGettingCityByStateInProgress(true));
      const response = await locationAPI.loadCitiesByState(state);

      if (response.status === "success") {
        dispatch(setCities(response.cities));
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error loading list of cities",
      });
    } finally {
      dispatch(setIsGettingCityByStateInProgress(false));
    }
  };

export const getCountiesByState =
  (state: string): LocationThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setCounties([]));
      dispatch(setIsGettingCountiesInProgress(true));
      const response = await locationAPI.loadCountiesByState(state);
      if (response.status === "success") {
        dispatch(setCounties(response.counties));
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error loading list of counties",
      });
    } finally {
      dispatch(setIsGettingCountiesInProgress(false));
    }
  };

export const getCountiesByFips =
  (fips: number): LocationThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setCounties([]));
      dispatch(setIsGettingCountiesInProgress(true));
      const response = await locationAPI.loadCountiesByFips(fips);

      if (response.status === "success") {
        dispatch(setCounties(response.counties));
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error loading list of counties",
      });
    } finally {
      dispatch(setIsGettingCountiesInProgress(false));
    }
  };

export const getCitiesByCounty =
  (countyFips: string): LocationThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setCities([]));
      dispatch(setIsGettingCityByStateInProgress(true));
      const response = await locationAPI.loadCitiesByCounty(countyFips);

      if (response.status === "success") {
        dispatch(setCities(response.cities));
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error loading list of cities",
      });
    } finally {
      dispatch(setIsGettingCityByStateInProgress(false));
    }
  };

export const getCitiesByPartName =
  (value: string): LocationThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsGettingCitiesByPartNameInProgress(true));
      const response = await locationAPI.loadCitiesByPartName(value);

      if (response.status === "success") {
        dispatch(setCitiesSuggestions(response.cities));
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error getting list of cities",
      });
    } finally {
      dispatch(setIsGettingCitiesByPartNameInProgress(false));
    }
  };
