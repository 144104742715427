import { ThunkAction } from "redux-thunk";
import { AppStateType } from "..";
import {
  SET_CALLS,
  SET_IS_CALLS_LOADING_IN_PROGRESS,
} from "../reducers/callsReducer";
import { callsApi } from "../../api";
import { notification } from "antd";
import { ICall } from "../../types";

type SetCalls = {
  type: typeof SET_CALLS;
  payload: { calls: ICall[] };
};

type SetIsCallsLoadingInProgress = {
  type: typeof SET_IS_CALLS_LOADING_IN_PROGRESS;
  payload: { isCallsLoadingInProgress: boolean };
};

export type CallsActionsTypes = SetCalls | SetIsCallsLoadingInProgress;

export const setCalls = (calls: ICall[]): SetCalls => ({
  type: SET_CALLS,
  payload: { calls },
});

export const setIsCallsLoadingInProgress = (
  isCallsLoadingInProgress: boolean
): SetIsCallsLoadingInProgress => ({
  type: SET_IS_CALLS_LOADING_IN_PROGRESS,
  payload: { isCallsLoadingInProgress },
});

export type CallsThunkType = ThunkAction<
  Promise<void>,
  AppStateType,
  unknown,
  CallsActionsTypes
>;

export const getCalls = (): CallsThunkType => async (dispatch, getState) => {
  try {
    dispatch(setIsCallsLoadingInProgress(true));
    const response = await callsApi.loadCalls();
    if (response.status === "success") {
      console.log(response.calls);
      dispatch(setCalls(response.calls));
    }
  } catch (e) {
    notification.error({
      message: "Error",
      description: "Error loading list of calls",
    });
  } finally {
    dispatch(setIsCallsLoadingInProgress(false));
  }
};
